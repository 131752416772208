import React from 'react'
import {trackOutboundLink} from 'analytics'
import styled from '@emotion/styled'
import {typography, padding, colors, margin} from 'styles'
import trelloIcon from 'images/trello-icon.svg'

const StyledAnchor = styled.a({
  textDecoration: 'none',
})

const Container = styled.div({
  display: 'inline-block',
})

const Button = styled.div({
  backgroundColor: 'white',
  border: `1px solid ${colors.lightGrey}`,
  borderRadius: 6,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 216,
  height: 60,
})

const Label = styled.p({
  ...padding.left(16),
  ...typography.headline3,
  color: colors.trelloBlue,
  '@supports (-moz-appearance:none)': {
    /* Firefox-only: fixes the tags being off-center */
    /* caused by something being wrong in the primary font */
    ...margin.top(4 as any),
  },
})

interface Props {
  url: string
}

const HugeAddToTrelloButton: React.FunctionComponent<Props> = ({url}) => (
  <StyledAnchor
    href={url}
    onClick={() => trackOutboundLink('Add to Trello', url)}
  >
    <Container>
      <Button>
        <img alt="Trello Logo" height="30" width="30" src={trelloIcon} />
        <Label>Add to Trello</Label>
      </Button>
    </Container>
  </StyledAnchor>
)

export default HugeAddToTrelloButton
