import styled from '@emotion/styled'
import React from 'react'
import {colors, margin, typography} from 'styles'
import {Spacing} from 'styles/constants/spacing'

export interface CardProps {
  image?: string
  imageSize?: number
  imageGap?: Spacing
  title?: string
  body: string
}

const Headline = styled.h2({
  ...typography.headline4,
  ...margin.bottom(16),
  textAlign: 'center',
})

const ImgContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
})

interface ImgProps {
  imageSize: number
  imageGap: Spacing
}

const Img = styled.img<ImgProps>((props) => ({
  ...margin.bottom(props.imageGap),
  width: props.imageSize,
  height: props.imageSize,
}))

const BodyText = styled.p({
  ...typography.body,
  color: colors.text,
  textAlign: 'center',
})

const Card: React.FunctionComponent<CardProps> = ({
  image,
  imageSize = 48,
  imageGap = 8,
  title,
  body,
}) => (
  <li>
    {image && (
      <ImgContainer>
        <Img src={image} imageSize={imageSize} imageGap={imageGap} />
      </ImgContainer>
    )}
    {title && <Headline>{title}</Headline>}
    <BodyText>{body}</BodyText>
  </li>
)

export default Card
