import React from 'react'
import {trackOutboundLink} from 'analytics'

interface Props {
  url: string
}

const OriginalAddToTrelloButton: React.FunctionComponent<Props> = ({url}) => (
  <a href={url} onClick={() => trackOutboundLink('Add to Trello', url)}>
    <img
      alt="Add to Trello"
      height="40"
      width="144"
      css={{display: 'block'}}
      src="https://p.trellocdn.com/add_to_trello.png"
      srcSet="https://p.trellocdn.com/add_to_trello.png 1x, https://p.trellocdn.com/add_to_trello@2x.png 2x"
    />
  </a>
)

export default OriginalAddToTrelloButton
