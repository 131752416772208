import styled from '@emotion/styled'
import {padding} from 'styles'

const ThreeColumnGrid = styled.ol({
  listStyle: 'none',
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'flex-start',
  justifyContent: 'center',
  '& li': {
    ...padding.x(16),
    ...padding.y(24),
    flexGrow: 1,
    flexBasis: 320,
    maxWidth: 480,
  },
})

export default ThreeColumnGrid
