import React from 'react'
import useSiteMetadata from 'hooks/useSiteMetadata'
import HugeAddToTrelloButton from './HugeAddToTrelloButton'
import OriginalAddToTrelloButton from './OriginalAddToTrelloButton'

interface Props {
  large?: boolean
}

const AddToTrelloButton: React.FunctionComponent<Props> = ({large}) => {
  const Button = large ? HugeAddToTrelloButton : OriginalAddToTrelloButton

  const {
    appUrls: {enablePowerUpUrl},
  } = useSiteMetadata()

  return <Button url={enablePowerUpUrl} />
}

export default AddToTrelloButton
