import React from 'react'
import styled from '@emotion/styled'
import {colors, typography, margin, breakpoint, padding} from 'styles'
import AddToTrelloButton from 'components/AddToTrelloButton'

const Container = styled.div({
  ...padding.bottom(48),
  textAlign: 'center',
  [breakpoint.smallAndUp]: {
    ...padding.bottom(100),
  },
})

const Headline = styled.h3({
  ...typography.headline3,
  ...margin.bottom(24),
  [breakpoint.smallAndUp]: {
    ...typography.headline2,
  },
})

const Lead = styled.p({
  ...margin.bottom(24),
  ...typography.body,
  color: colors.textLight,
})

const BottomCTA = () => (
  <Container>
    <Headline>Try Hello Epics free for 14 days</Headline>
    <Lead>No credit card required</Lead>
    <AddToTrelloButton large />
  </Container>
)

export default BottomCTA
